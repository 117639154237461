li.active > a{
    background-color: $primary-color !important;
    color: $white !important;
}
.page-link{
    border: 1px solid $secondary-color !important;
    color: $primary-color !important;
}
.page-link:active{
    font-weight: bold;
}
.scroll-container {
    overflow-x: auto;
}
.numbers:hover {
    background-color: $secondary-color !important;
}
.label-sm, label{
    font-size: xx-small;
    color: $text-secondary-color !important;
}
.arrow{
    margin-right: 3rem;
}
.page-item .first-page {
    background-color: $secondary-color !important;
}
.page-item .selected-page {
    background-color: $secondary-color !important;
}
.form-check-label{
    font-size: small !important;
}