@media (max-width: 450px) {
    /* … 
.disapearMobil{ display: none;}*/

}

.rem09 {
    font-size: 0.9rem!important;
}
.img-c {display: flex;
    justify-content: center; }
.centradoTextosParams {vertical-align: middle;
    justify-content: center;
    align-items: center;
    display: flex;}
    .backgroundg {background-color: rgb(235, 234, 234);}
.align-c{align-self: center;}
.col-9-important {flex: 0 0 auto!important;
    width: 75%!important;
   
    float: left;
    display: flex;
    height: 100%;
}
.gparameter {
    color: white;
    background-color: #B8131A;
}
.smallFileText { font-size: 7px;}
.titelform {
    max-width: 250px;
    text-align: center;
    font-size: 18px;
    margin-top: -12px;
    margin-left: 7px;
    background: white;
}
.fs-empresa{font-size: 0.9rem;}
.pl5 {    padding-left: 0px;
}
.formparameter {
    margin-top: 20px;

    border: 2px solid black;
    border: 1px solid #d5d2d2;
    border-radius: 14px;
    padding: 2px 10px 10px 10px;
}

.textreaction {
    padding-left: 19px;
    padding-top: 7px;font-size: 0.9vw;font-size: 0.9rem;
}

.btnactiv {
    width: 102%;/* font-size: 0.9vw!important; */ margin-top: 0px; margin-left: -5px;
}

.arrow {
    margin-right: 3rem;
}

.th,
.modal-title,
.users {
    font-weight: bold;
}

.label-sm {
    font-size: xx-small;
    color: gray;
}

.table-th {
    min-width: 150px !important;
    width: 20vh;
    overflow: auto;
}

table {
    table-layout: fixed;
    border: transparent !important;
}

.scroll-container {
    overflow-x: auto;
}

.numbers:hover {
    background-color: lightgray !important;
}

.btn-secondary-dark {
    background-color: lightgray !important;
    color: white;
}

.btn-secondary-dark:hover {
    color: lightgray !important;
    background-color: transparent !important;
}
.page-item .first-page {
    background-color: $secondary-color !important;
  }
  .page-item .selected-page {
    background-color: $secondary-color !important;
  }
  .loader  {
    position: absolute;
    width: 100%;
    background: white;
    z-index: 777;
    height: 90px;
  }