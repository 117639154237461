.btn-primary-custom{
    background-color: $primary-color;
    border-color: $primary-color !important;
    color: $white ;
    border-radius: 5px;
}
.btn{
    box-shadow: none !important;
}
.btn-primary-custom:hover{
    border: 1px solid $primary-color !important;
    color: $primary-color !important;
    background-color: transparent;
    font-weight: bold;
}
.btn-secondary-custom{
    color: $text-secondary-color !important;
    background-color: transparent !important;
    border-radius: 5px;
}
.btn-secondary-custom:hover{
    background-color: $secondary-color !important;
    color: $white !important;
}
.btn-secondary-dark, .swal2-success-ring{
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;
    color: $white;
}
.btn-secondary-dark:hover{
    color: $secondary-color !important;
    background-color: transparent !important;
}
.btn-secondary-dark:hover, .swal2-success-ring, .swal2-success-circular-line-right, .swal2-success-fix, .swal2-success-circular-line-left, .swal2-icon{
    color: $primary-color !important;
    background-color: transparent !important;
}
.swal2-confirm, button:where(.swal2-styled).swal2-confirm, .swal2-success-line-tip, .swal2-success-line-long{
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: $white !important;
    border-radius: 5px;
    transition: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
}
.swal2-icon{
    border-color: $secondary-color !important;
}
.dropdown-toggle{
    background-color: $primary-color;
    color: $white;
    border-radius: 5px;
}
.dropdown-toggle:hover{
    border: 1px solid $primary-color;
    border-radius: 5px;
}

.form-check-input:checked {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
}
.bigger{
    font-size: 20px;
}