@media (max-width: 450px) {
    .ml{
        margin-left: 0px;
    }
    .logo{
        display: none;
    }
    .background-colored{
        padding: 0;
    }
    .sidebar{
        width: 100% !important;
        padding-bottom: 30px !important;
        margin: 0 !important;
    }
    .list-group-item{
        text-align: center;
    }
    .table-container{
        display: block;
    }
    table{
        display: block;
        overflow: auto;
    }
    .ReactModal__Content{
        min-width: unset !important;
    }
    .form-select{
        margin-bottom: 10px;
    }
    .header-container{
        display: block;
        text-align: center;
    }
}