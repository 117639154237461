.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 58px 0 0; 
    border: none;
    width: 240px;
    z-index: 0;
    background-color: lightgray !important;
}

@media (max-width: 450px) {
    .sidebar{
        position: relative;
    }
}

.list-group-item.active {
    color: $primary-color !important;
    border: none;
    font-weight: bold;
}
.customized {
    color: $primary-color !important;
    border: none;
    font-weight: bold;
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto; 
}

.list-group-item{
    background-color: lightgray !important;
    border: none;
    color: black;
}
.list-group-item:hover{
    background-color: rgb(203, 203, 203) !important;
}
.form-control + div{
    color: $primary-color;
    font-size: small;
    padding: 0 10px;
}

.logout{
    color: #757474 !important;
    font-weight: bold !important;
}