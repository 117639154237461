.th, .modal-title, .users{
    font-weight: bold;
}
.table-report-th{
    width: 250px;
    overflow: auto;
}
.table-th{
    min-width: 150px !important;
    width: 20vh;
    overflow: auto;
}
.th, .modal-title{
    font-weight: bold;
}
.th{
    min-width: 10rem;
}
table{
    table-layout: fixed;
    width: unset !important;
    min-width: 100%;
}
.table-container{
    display: flex;
    justify-content: center;
}
