.chart-container{
    display: flex;
    justify-content: center;
}
.chart-general-container{
    display: grid;
    justify-items: center;
}
.doughnut{
    max-width: 300px;
    max-height: 300px;
}
.speedometer{
    max-height: 200px;
}
.text-group{
    text{
        fill: black !important;
    }
}
// .needle{
//     path{
//         fill: #e1cb3a !important;
//     }
// }