@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  .fs-empresa{font-size: 0.9rem;}
  body{
    font-family: $font;
}
.background-colored{
    background-color: $background-color;
    min-height: 100vh;
    padding: 3rem;
}
.datatable-container{
    background-color: $white;
    padding: 10px 40px !important;
    min-height: 10vh;
}
.background-container{
    height: 100vh;
}
.background{
    width: 100%;
    height: 100%;
}
.bold{
    font-weight: bold;
}
.header-container{
    padding: 20px 20px 0px 20px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}    
.header-label{
    text-transform: uppercase;
    font-weight: bold;
}
.ReactModal__Overlay{
    background-color: rgb(243, 242, 242, 0.75) !important;
    align-content: center;
    display: grid;
    justify-content: center;
}
.ReactModal__Content{
    position: relative !important;
    max-width: 750px; 
    min-width: 750px; 
    display: grid;
    align-items: center;
    inset: unset !important;
}
.dt-bootstrap4+div+div{
    justify-content: center !important;
}
[data-test="mdb-datatable-table"] {
    padding: 25px;
}
.ml{
    margin-left: 240px;
}
.logo{
    max-width: 200px;
}
.tamanspid{
    width: 12px;height: 12px;
}
.show{
    display: block;
}
.hidden{
    display: none;
}
.numbers:focus{
    background-color: $secondary-color !important;
}
.page-item .first-page {
    background-color: $secondary-color !important;
}