.form-control:focus{
    border: 1px solid $secondary-color !important;
    border-color: $primary-color;
    outline: none;
    box-shadow: none;
}
.error-message { 
    color: $primary-color; 
    font-weight: bold; 
    padding: 0 10px;
}
label{
    font-size: small;
    font-weight: bold;
}
sup{
    color: $primary-color;
}
input{
    font-size: small !important;
    border-color: transparent;
}
.select{
font-size: small !important;
}